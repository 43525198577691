import React, { useState, useEffect, useRef } from "react";
import numeral from "numeral";
import { getDataBatch } from "../../service/ratio/RatioService";
import Full from "../../load/Loading";
import Swal from "sweetalert2";
import StockSubdistBatch from "./StokSubdistBatch";

function StockSubdistProduk({
  dataProduk,
  subdistId,
  rank,
  namaCabang,
  NilaiStok,
  AvgSales,
  DoiValue,
  sellerId,
}) {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  useEffect(() => {
    initializeDataTables();
    setDataBatch([]);
  }, [dataProduk]);

  //konversi nilai
  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  const [dataBatch, setDataBatch] = useState([]);
  const [namaProduk, setNamaProduk] = useState("");
  const [unitBatch, setUnitBatch] = useState("");
  const sortedData = dataProduk.sort((a, b) => {
    if (a.doiValue === null) return -1; // Place null values first
    if (b.doiValue === null) return 1; // Place other values after null
    return b.doiValue - a.doiValue; // Sort by doiValue for non-null values
  });

  const handleBatch = (nextTable, nameProduk, produkListId) => {
    const payload = {
      rank: rank,
      subdist_code: subdistId,
      search_name: nextTable,
      cabang: sellerId,
      product_code: produkListId,
    };
    setNamaProduk(nameProduk);
    showLoader();
    getDataBatch(payload)
      .then((res) => {
        if ((res.data.success = "true")) {
          setDataBatch(res.data.data);
          setUnitBatch(res.data.subtotal_unitBatch);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  return (
    <>
      {dataProduk.length > 0 ? (
        <section className="">
          <div style={{ marginTop: "30px" }}>
            <div className="border-[1px] border-[#437998] rounded-xl p-2">
              <h3 className="font-semibold text-lg mx-2">
                Produk - <span className="text-red-600">{namaCabang}</span>
              </h3>
              <div className="">
                <div className="card-body">
                  <table
                    className="table table-bordered table-hover table-scroll"
                    id="example501"
                    style={{
                      fontSize: "12px",
                      fontWeight: "semiBold",
                      display: "block",
                      overflow: "scroll",
                      height: "420px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#6BB7B7",
                        color: "white",
                        textAlign: "center",
                        top: "0",
                        zIndex: "10",
                        position: "sticky",
                        fontWeight: "reguler",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            left: "0",
                            zIndex: "5",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#6BB7B7",
                          }}
                        >
                          NO
                        </th>
                        <th
                          style={{
                            left: "40px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#6BB7B7",
                          }}
                        >
                          KODE PRODUK
                        </th>

                        <th
                          style={{
                            left: "60px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#6BB7B7",
                          }}
                        >
                          NAMA PRODUK
                        </th>
                        <th
                          style={{
                            left: "60px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#6BB7B7",
                          }}
                        >
                          UNIT STOK
                        </th>
                        <th>UNIT RATA-RATA SALES</th>
                        <th>NILAI STOK</th>
                        <th>NILAI RATA-RATA SALES</th>
                        <th>DOI</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "right" }}>
                      {sortedData.map((list, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              textAlign: "center",
                              left: "0px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                            width={"1%"}
                          >
                            {index + 1}.
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              left: "40px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {list.KodeProduk}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              left: "60px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {list.NamaProduk}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              left: "60px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <span
                              className={`${
                                parseFloat(list.UnitStock) < 0
                                  ? "text-red-500"
                                  : "text-[#5077C4] underline cursor-pointer"
                              }`}
                              onClick={() =>
                                handleBatch(
                                  list.next,
                                  list.NamaProduk,
                                  list.product_list_id
                                )
                              }
                            >
                              {formatToRupiah(list.UnitStock)}
                            </span>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <span
                              className={`${
                                parseFloat(list.avg) < 0 ? "text-red-500" : ""
                              }`}
                            >
                              {parseFloat(list.avg).toFixed(2)}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.ValueStock) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatToRupiah(list.ValueStock)}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.avgValue) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatToRupiah(list.avgValue)}
                            </span>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <span
                              className={`${
                                parseFloat(list.doiValue) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {list.doiValue !== null ? (
                                <span> {list.doiValue}</span>
                              ) : (
                                <span>&infin;</span>
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className="sticky bottom-0 z-10">
                      <tr className="bg-white border border-slate-800 font-bold">
                        <th colSpan={"5"} className="text-center">
                          TOTAL
                        </th>
                        <th className="text-right">
                          {" "}
                          <span
                            className={`${
                              parseFloat(NilaiStok) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {formatToRupiah(NilaiStok)}
                          </span>
                        </th>
                        <th className="text-right">
                          {" "}
                          <span
                            className={`${
                              parseFloat(AvgSales) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {formatToRupiah(AvgSales)}
                          </span>
                        </th>
                        <th className="text-center">
                          {" "}
                          <span
                            className={`${
                              parseFloat(DoiValue) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {DoiValue}
                          </span>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {dataBatch.length ? (
            <>
              <StockSubdistBatch
                dataBatch={dataBatch}
                namaProduk={namaProduk}
                unitBatch={unitBatch}
              />
            </>
          ) : null}
        </section>
      ) : null}

      {loader}
    </>
  );
}

export default StockSubdistProduk;
