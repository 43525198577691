import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  getDataRatio,
  getDataRatioDetail,
  getDataChart,
  getListChoice,
  getListChoiceSubdist,
  getlistDropDown,
  getDataSubdist,
} from "../../service/ratio/RatioService";
import Full from "../../load/Loading";
import "../../App.css";
import Swal from "sweetalert2";
import numeral from "numeral";
import Highcharts from "highcharts";
import GrafikHigh from "./GrafikHigh";
import ChartOne from "./ChartOne";
import ChartTwo from "./ChartTwo";
import ChartThree from "./ChartThree";
import ChartFour from "./ChartFour";
import ChartFive from "./ChartFive";
import MonitorData from "./MonitorData";
import ModalUserLog from "./ModalUserLog";
import StockSubdist from "./StokSubdist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
const stopwatch = <FontAwesomeIcon icon={faStopwatch} />;

function MainDashboard() {
  const params = useParams();
  //navigasi
  const targetRef1 = useRef(null);
  const targetRef2 = useRef(null);
  const targetRef3 = useRef(null);
  const targetRef4 = useRef(null);
  const targetRef5 = useRef(null);
  //loading
  const [loader, showLoader, hideLoader] = Full();
  //start untuk priode
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const pastYear = currentDate.getFullYear() - 1;
  const currentMonth = currentDate.getMonth() + 1;
  const currentMonthString = String(currentMonth).padStart(2, "0");
  const monthNow = currentYear + "-" + currentMonthString;
  //get localstrorage
  const rank_name = localStorage.getItem("rank_name");
  const rank = localStorage.getItem("rank");
  const id_type = localStorage.getItem("id_type");
  const team_id = localStorage.getItem("team_id");
  const months = [];

  // Mengisi array dengan bulan-bulan dari Desember 2023 hingga bulan saat ini
  for (let year = pastYear; year <= currentYear; year++) {
    const startMonth = year === pastYear ? 12 : 1; // Jika tahun 2023, mulai dari bulan Desember, jika bukan, mulai dari Januari
    const endMonth = year === currentYear ? currentMonth : 12; // Jika tahun ini, berhenti di bulan saat ini, jika bukan, berhenti di bulan Desember

    for (let month = startMonth; month <= endMonth; month++) {
      months.push({
        value: `${year}-${String(month).padStart(2, "0")}`,
        label: moment(`${year}-${String(month).padStart(2, "0")}`).format(
          "MM - YYYY"
        ),
      });
    }
  }

  // Mengurutkan array months berdasarkan bulan, dimulai dari yang terbaru
  months.sort((a, b) => new Date(b.value) - new Date(a.value));

  //show piechart
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);

  //priode
  const [month, setMonth] = useState("");

  //untuk setting title
  const [name0, setName0] = useState("");
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const [name4, setName4] = useState("");
  const [nameLast, setNameLast] = useState("");

  //bulan tahun
  const [monthPrev, setMonthPrev] = useState("");

  const convertMonthFormat = (monthNow) => {
    const [year, month] = monthNow.split("-");
    const monthNames = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const monthName = monthNames[parseInt(month) - 1];
    return `${monthName} ${year}`;
  };
  const formattedMonth = convertMonthFormat(monthNow);

  const convertMonthFormat1 = (monthPrev) => {
    const [year, month] = monthPrev.split("-");
    const monthNames = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const monthName = monthNames[parseInt(month) - 1];
    return `${monthName} ${year}`;
  };
  const formattedMonth1 = convertMonthFormat1(monthPrev);

  let nowDate;
  if (monthPrev == "") {
    nowDate = formattedMonth;
  } else {
    nowDate = formattedMonth1;
  }

  //untuk piechart pertama load
  const [ratioSTO, setDataRatioSTO] = useState({});
  const [ratioBDU, setDataRatioBDU] = useState({});
  const [ratioNew, setDataRatioNew] = useState({});

  //untuk piechart detail
  const [ratioDetail1, setDataRatioDetail1] = useState({});
  const data1 = Object.values(ratioDetail1).map((region) => region.data[0]);
  // data turunan pertama
  const transformedData = Object.values(ratioDetail1).map((item) => {
    const newItem = { ...item.data[0] };
    newItem.subtotal = newItem.subtotal.replace(/,/g, "");
    return newItem;
  });

  const sortedData = transformedData.sort((a, b) => b.subtotal - a.subtotal);
  // Inisialisasi variabel total
  let totalSales = 0;
  let totalOutlet = 0;

  // Map dataOutlet dan tambahkan nilai ke total
  transformedData.forEach((list) => {
    totalSales += parseInt(list.subtotal);
    totalOutlet += parseInt(list.outlet_transaksi);
  });

  const [ratioDetail2, setDataRatioDetail2] = useState({});
  // data turunan pertama
  const transformedData2 = Object.values(ratioDetail2).map((item) => {
    const newItem = { ...item.data[0] };
    newItem.subtotal = newItem.subtotal.replace(/,/g, "");
    return newItem;
  });
  const sortedData2 = transformedData2.sort((a, b) => b.subtotal - a.subtotal);
  // Inisialisasi variabel total
  let totalSales2 = 0;
  let totalOutlet2 = 0;

  // Map dataOutlet dan tambahkan nilai ke total
  transformedData2.forEach((list) => {
    totalSales2 += parseInt(list.subtotal);
    totalOutlet2 += parseInt(list.outlet_transaksi);
  });

  const [ratioDetail3, setDataRatioDetail3] = useState({});
  // data turunan pertama
  const transformedData3 = Object.values(ratioDetail3).map((item) => {
    const newItem = { ...item.data[0] };
    newItem.subtotal = newItem.subtotal.replace(/,/g, "");
    return newItem;
  });
  const sortedData3 = transformedData3.sort((a, b) => b.subtotal - a.subtotal);
  // Inisialisasi variabel total
  let totalSales3 = 0;
  let totalOutlet3 = 0;

  // Map dataOutlet dan tambahkan nilai ke total
  transformedData3.forEach((list) => {
    totalSales3 += parseInt(list.subtotal);
    totalOutlet3 += parseInt(list.outlet_transaksi);
  });

  const [ratioDetail4, setDataRatioDetail4] = useState({});
  // data turunan pertama
  const transformedData4 = Object.values(ratioDetail4).map((item) => {
    const newItem = { ...item.data[0] };
    newItem.subtotal = newItem.subtotal.replace(/,/g, "");
    return newItem;
  });
  const sortedData4 = transformedData4.sort((a, b) => b.subtotal - a.subtotal);
  // Inisialisasi variabel total
  let totalSales4 = 0;
  let totalOutlet4 = 0;

  // Map dataOutlet dan tambahkan nilai ke total
  transformedData4.forEach((list) => {
    totalSales4 += parseInt(list.subtotal);
    totalOutlet4 += parseInt(list.outlet_transaksi);
  });

  const [dataChartBar, setDataChartBar] = useState({});
  const dataArray = Object.entries(dataChartBar).map(([key, value]) => {
    return key;
  });
  // Inisialisasi array untuk menyimpan data yang diubah
  const regionalData = [];

  // Loop melalui setiap merek
  Object.keys(dataChartBar).forEach((merek) => {
    const regionalNames = Object.keys(dataChartBar[merek]);
    const regionalValues = Object.values(dataChartBar[merek]);

    // Loop melalui setiap regional name dan value untuk mendapatkan data yang sesuai
    regionalNames.forEach((regionalName, index) => {
      if (regionalValues[index] !== null) {
        // Cari atau buat entry sesuai nama regional
        const regionalEntry = regionalData.find(
          (entry) => entry.name === regionalName
        );
        if (regionalEntry) {
          // Jika entry sudah ada, tambahkan value
          regionalEntry.data.push(parseInt(regionalValues[index]));
        } else {
          // Jika entry belum ada, buat entry baru
          regionalData.push({
            name: regionalName,
            data: [parseInt(regionalValues[index])],
          });
        }
      }
    });
  });

  // setting warna
  const [colorPopup, SetColorPopup] = useState("");

  //setting tiperatio
  const [tipeRatio, setTipeRatio] = useState("");

  //untuk table
  const [Tipe, setType] = useState("");
  const [Tipers, setTypes] = useState("");
  const [idTeam, setIdTeam] = useState("");
  const [idTeam1, setIdTeam1] = useState("");
  const [idTeam2, setIdTeam2] = useState("");
  const [idTeam3, setIdTeam3] = useState("");
  const [Mark, setMark] = useState("");

  //load pertama kali
  useEffect(() => {
    DataRatio();
  }, []);

  //ketika pilih priode
  const handleMonth = () => {
    DataRatio();
    setShow1(false);
    setShow2(false);
    setShow3(false);
    setShow4(false);
    setShow5(false);
  };

  //ketika pilih subdist
  const handleSubdist = () => {
    DataSubdist();
  };

  //function first load
  const DataRatio = () => {
    const payload = {
      periode: month ? month : "",
    };
    showLoader();
    getDataRatio(payload)
      .then((res) => {
        if (res.data.data["BDU"] == "0" || res.data.data["STO"] == "0") {
          Swal.fire({
            title: "Belum Ada Data Saat ini",
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        } else {
          setDataRatioSTO(res.data.data[0]);
          setDataRatioBDU(res.data.data[1]);
          setMonthPrev(month);

          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const [listSubdist, setListSubdist] = useState([]);

  const [subdistId, setSubdist] = useState("");
  const [dataSubdist, setDataSubdist] = useState([]);
  const [waktuProses, setWaktuProse] = useState("");
  const [NilaiStok, setNilaiStok] = useState("");
  const [AvgSales, setAvgSales] = useState("");
  const [DoiValue, setDoiValue] = useState("");

  useEffect(() => {
    setDataSubdist([]);
    // setListSubdist([]);
    setWaktuProse("");
  }, [subdistId, Mark]);

  useEffect(() => {
    ChoiceFirstSubdist();
    setListSubdist([]);
  }, [Mark]);

  useEffect(() => {
    setSubdist("");
  }, [Mark]);

  //function subdist
  const DataSubdist = () => {
    const payload = {
      rank: Mark,
      subdist_code: subdistId,
      search_name: "detail_cabang",
      cabang: "",
      product_code: "",
    };
    showLoader();
    getDataSubdist(payload)
      .then((res) => {
        if ((res.data.success = "true")) {
          setDataSubdist(res.data.data);
          setWaktuProse(res.data.stock_date);
          setNilaiStok(res.data.subtotal_totalValue);
          setAvgSales(res.data.subtotal_avgValue);
          setDoiValue(res.data.subtotal_doiValue);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //klik untuk turunan pertama
  const handleSliceClick = (type, name, drilldown1, divisi, color) => {
    if (targetRef1.current) {
      targetRef1.current.scrollIntoView({ behavior: "smooth" });
    }
    setType(type);
    setIdTeam(drilldown1);
    setName0(name);
    setShow2(false);
    setShow3(false);
    setShow4(false);
    setShow5(false);
    setMark(divisi);
    SetColorPopup(color);
    localStorage.setItem("rank", divisi);

    let payload;
    if (id_type == "0") {
      payload = {
        rank: divisi ? divisi : params.id,
        type: type,
        team_id_01: drilldown1 ? drilldown1 : "",
        team_id_02: "",
        team_id_03: "",
        team_id_04: "",
        team_id: "",
        id_type: "",
        periode: month ? month : "",
      };
    } else if (id_type == "1") {
      payload = {
        rank: divisi ? divisi : params.id,
        type: type,
        team_id_01: "",
        team_id_02: drilldown1 ? drilldown1 : "",
        team_id_03: "",
        team_id_04: "",
        team_id: "",
        id_type: "",
        periode: month ? month : "",
      };
    } else if (id_type == "2") {
      payload = {
        rank: divisi ? divisi : params.id,
        type: type,
        team_id_01: "",
        team_id_02: "",
        team_id_03: drilldown1 ? drilldown1 : "",
        team_id_04: "",
        team_id: "",
        id_type: "",
        periode: month ? month : "",
      };
    } else if (id_type == "3") {
      payload = {
        rank: divisi ? divisi : params.id,
        type: type,
        team_id_01: "",
        team_id_02: "",
        team_id_03: "",
        team_id_04: drilldown1 ? drilldown1 : "",
        team_id: "",
        id_type: "",
        periode: month ? month : "",
      };
    }
    showLoader();
    getDataRatioDetail(payload)
      .then((res) => {
        if (res.data.data["BDU"] == "0" || res.data.data["STO"] == "0") {
          Swal.fire({
            title: "Belum Ada Data Saat ini",
            showConfirmButton: false,
            icon: "warning",
          });
          setShow1(false);
          hideLoader();
        } else {
          setDataRatioDetail1(res.data.data);
          setShow1(true);
          hideLoader();
          initializeDataTables();
          const payloads = {
            periode: month ? month : "",
            rank: divisi,
          };

          getDataChart(payloads)
            .then((res) => {
              setDataChartBar(res.data.data);
            })
            .catch((err) => {
              if (err.response.data.message == "Expired token") {
                Swal.fire({
                  title: err.response.data.message,
                  showConfirmButton: false,
                  icon: "warning",
                });
                localStorage.removeItem("token");
                localStorage.removeItem("rank_name");
                localStorage.removeItem("division_id");
                localStorage.removeItem("team_id");
                localStorage.removeItem("id_type");
                localStorage.removeItem("rank");
                localStorage.removeItem("color");
                localStorage.removeItem("is_manager");
                window.location = "/";
                hideLoader();
              }
            });
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //klik untuk turunan kedua
  const handleSliceClick2 = (ranks, team_1, name) => {
    if (targetRef2.current) {
      targetRef2.current.scrollIntoView({ behavior: "smooth" });
    }
    setIdTeam(team_1);
    setName1(name);
    setShow3(false);
    setShow4(false);
    setShow5(false);
    const payload = {
      rank: ranks ? ranks : params.id,
      type: tipeRatio,
      team_id_01: team_1 ? team_1 : "",
      team_id_02: "",
      team_id_03: "",
      team_id_04: "",
      team_id: "",
      id_type: "",
      periode: month ? month : "",
    };
    showLoader();
    getDataRatioDetail(payload)
      .then((res) => {
        if (res.data.data["BDU"] == "0" || res.data.data["STO"] == "0") {
          Swal.fire({
            title: "Belum Ada Data Saat ini",
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        } else {
          setDataRatioDetail2(res.data.data);
          setShow2(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //klik untuk turunan ketiga
  const handleSliceClick3 = (ranks, team_1, team_2, name) => {
    if (targetRef3.current) {
      targetRef3.current.scrollIntoView({ behavior: "smooth" });
    }
    setIdTeam1(team_2);
    setName2(name);
    setShow4(false);
    setShow5(false);
    const payload = {
      rank: ranks ? ranks : params.id,
      type: tipeRatio,
      team_id_01: team_1 ? team_1 : "",
      team_id_02: team_2 ? team_2 : "",
      team_id_03: "",
      team_id_04: "",
      team_id: "",
      id_type: "",
      periode: month ? month : "",
    };
    showLoader();
    getDataRatioDetail(payload)
      .then((res) => {
        if (res.data.data["BDU"] == "0" || res.data.data["STO"] == "0") {
          Swal.fire({
            title: "Belum Ada Data Saat ini",
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        } else {
          setDataRatioDetail3(res.data.data);
          setShow3(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //klik untuk turunan keempat
  const handleSliceClick4 = (ranks, team_1, team_2, team_3, name) => {
    if (targetRef4.current) {
      targetRef4.current.scrollIntoView({ behavior: "smooth" });
    }
    setName3(name);
    setIdTeam2(team_3);
    const payload = {
      rank: ranks ? ranks : params.id,
      type: tipeRatio,
      team_id_01: team_1 ? team_1 : "",
      team_id_02: team_2 ? team_2 : "",
      team_id_03: team_3,
      team_id_04: "",
      team_id: "",
      id_type: "",
      periode: month ? month : "",
    };
    showLoader();
    getDataRatioDetail(payload)
      .then((res) => {
        if (res.data.data["BDU"] == "0" || res.data.data["STO"] == "0") {
          Swal.fire({
            title: "Belum Ada Data Saat ini",
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        } else {
          setDataRatioDetail4(res.data.data);
          setShow4(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //klik untuk pindah page detail
  const handleSliceClick5 = (ranks, team_4, name) => {
    setTypes(ranks);

    if (targetRef5.current) {
      targetRef5.current.scrollIntoView({ behavior: "smooth" });
    }
    setIdTeam3(team_4);
    setName4(name);
    setNameLast(tipeRatio);
    setShow5(true);
  };

  //showing piechart sto
  useEffect(() => {
    Highcharts.chart("containerSTO", {
      chart: {
        type: "pie",
      },
      title: {
        text: `${ratioSTO.name_data} (${nowDate})`,
        align: "center",
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        series: {
          borderRadius: 5,
          cursor: "pointer",
          dataLabels: [
            {
              enabled: true,
              distance: 8,
              format: "{point.name}",
              style: {
                fontSize: "12px",
                textOutline: "none",
              },
            },
            {
              enabled: true,
              distance: "-30%",
              filter: {
                property: "percentage",
                operator: ">",
                value: 5,
              },
              format: "{point.y:.1f}%",
              style: {
                fontSize: "12px",
                textOutline: "none",
                color: "#ffffff",
              },
            },
          ],
          // Handle event click pada setiap data
          point: {
            events: {
              click: function () {
                if (this.y !== 0 && this.name != null) {
                  if (id_type == "0") {
                    handleSliceClick(
                      ratioSTO.name_data,
                      this.name,
                      this.drilldown_1,
                      this.divisi,
                      this.color
                    );
                  } else if (id_type == "1") {
                    handleSliceClick(
                      ratioSTO.name_data,
                      this.name,
                      this.drilldown_2,
                      this.divisi,
                      this.color
                    );
                  } else if (id_type == "2") {
                    handleSliceClick(
                      ratioSTO.name_data,
                      this.name,
                      this.drilldown_3,
                      this.divisi,
                      this.color
                    );
                  } else if (id_type == "3") {
                    handleSliceClick5(
                      ratioSTO.name_data,
                      this.name,
                      this.drilldown_4
                    );
                  }
                  setTipeRatio(ratioSTO.name_data);
                }
              },
            },
          },
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.subtotal}</b> of total <b>{point.grandtotal}</b> <br> Achievement <b>{point.target_pct} % ({point.subtotal} </b> of total <b>{point.target}</b>)</b><br/>',
      },
      series: [ratioSTO],
    });
  }, [ratioSTO]);

  useEffect(() => {
    Highcharts.chart("containerBDU", {
      chart: {
        type: "pie",
      },
      title: {
        text: `${ratioBDU.name_data} (${nowDate})`,
        align: "center",
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        series: {
          borderRadius: 5,
          cursor: "pointer",
          dataLabels: [
            {
              enabled: true,
              distance: 8,
              format: "{point.name}",
              style: {
                fontSize: "12px",
                textOutline: "none",
              },
            },
            {
              enabled: true,
              distance: "-30%",
              filter: {
                property: "percentage",
                operator: ">",
                value: 5,
              },
              format: "{point.y:.1f}%",
              style: {
                fontSize: "12px",
                textOutline: "none",
                color: "#ffffff",
              },
            },
          ],
          // Handle event click pada setiap data
          point: {
            events: {
              click: function () {
                if (this.y !== 0 && this.name != null) {
                  if (id_type == "0") {
                    handleSliceClick(
                      ratioBDU.name_data,
                      this.name,
                      this.drilldown_1,
                      this.divisi,
                      this.color
                    );
                  } else if (id_type == "1") {
                    handleSliceClick(
                      ratioBDU.name_data,
                      this.name,
                      this.drilldown_2,
                      this.divisi,
                      this.color
                    );
                  } else if (id_type == "2") {
                    handleSliceClick(
                      ratioBDU.name_data,
                      this.name,
                      this.drilldown_3,
                      this.divisi,
                      this.color
                    );
                  } else if (id_type == "3") {
                    handleSliceClick5(
                      ratioBDU.name_data,
                      this.name,
                      this.drilldown_4
                    );
                  }
                  setTipeRatio(ratioBDU.name_data);
                }
              },
            },
          },
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.subtotal}</b> of total <b>{point.grandtotal}</b> <br> Achievement <b>{point.target_pct} % ({point.subtotal} </b> of total <b>{point.target}</b>)</b><br/>',
      },
      series: [ratioBDU],
    });
  }, [ratioBDU]);

  const [team_idMonitor, setTeamIdMonitor] = useState("");
  const [listChoiceFirst, setListChoiceFirst] = useState([]);

  const [dataChoice, setChoice] = useState([]);
  const [dataChoice1, setChoice1] = useState([]);
  const [dataChoice2, setChoice2] = useState([]);

  const [next, setTypeNext] = useState("");
  const [next1, setTypeNext1] = useState("");
  const [next2, setTypeNext2] = useState("");

  const [labelArea, setLabelArea] = useState("");
  const [labelArea1, setLabelArea1] = useState("");
  const [labelArea2, setLabelArea2] = useState("");
  const [nameMonitoring, setNameMonitoring] = useState("");
  const [idTeams, setidTeam] = useState("");

  useEffect(() => {
    // Automatically trigger handleChange2 when dataChoice1 has exactly one item
    if (dataChoice2.length === 1) {
      const selectedValue = {
        team_id: dataChoice2[0].team_id,
        team_name: dataChoice2[0].team_name,
      };
      handleChange3({ target: { value: JSON.stringify(selectedValue) } });
    }
  }, [dataChoice2]);

  useEffect(() => {
    setLabelArea1("");
    setLabelArea2("");
    setTeamIdMonitor("");
  }, [dataChoice]);

  useEffect(() => {
    setLabelArea2("");
    setTeamIdMonitor("");
  }, [dataChoice1]);

  useEffect(() => {
    setTeamIdMonitor("");
  }, [dataChoice2]);

  const ChoiceFirst = () => {
    showLoader();
    const payload = {
      rank_name: rank_name,
      rank: rank,
      periode: month,
      team_id: team_id,
    };

    getListChoice(payload)
      .then((res) => {
        if (res.data.success === true) {
          const dataList = [];
          for (const key in res.data.data) {
            if (Object.hasOwnProperty.call(res.data.data, key)) {
              const element = res.data.data[key];
              dataList.push(element);
            }
          }
          setListChoiceFirst(dataList);
          const types = dataList.map((list) => list.type).join(", ");

          setTypeNext(types);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const ChoiceFirstSubdist = () => {
    showLoader();
    const payload = {
      rank: Mark,
    };
    getListChoiceSubdist(payload)
      .then((res) => {
        if (res.data.success === true) {
          setListSubdist(res.data.data);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const handleChange = async (event) => {
    SelectChoice(event.target.value);
  };

  const SelectChoice = (team_id) => {
    showLoader();
    const payload = {
      rank: rank,
      periode: month,
      type: next,
      team_id: team_id,
    };

    getlistDropDown(payload)
      .then((res) => {
        if (res.data.success === true) {
          setChoice(res.data.data.list);
          setTypeNext1(res.data.data.next);
          setLabelArea(res.data.data.label);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const handleChange1 = async (event) => {
    SelectChoice1(event.target.value);
  };

  const SelectChoice1 = (team_id) => {
    showLoader();
    const payload = {
      rank: rank,
      periode: month,
      type: next1,
      team_id: team_id,
    };

    getlistDropDown(payload)
      .then((res) => {
        if (res.data.success === true) {
          setChoice1(res.data.data.list);
          setTypeNext2(res.data.data.next);
          setLabelArea1(res.data.data.label);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const handleChange2 = async (event) => {
    SelectChoice2(event.target.value);
  };

  const SelectChoice2 = (team_id) => {
    showLoader();
    const payload = {
      rank: rank,
      periode: month,
      type: next2,
      team_id: team_id,
    };
    getlistDropDown(payload)
      .then((res) => {
        if (res.data.success === true) {
          setChoice2(res.data.data.list);

          setLabelArea2(res.data.data.label);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const handleChange3 = async (event) => {
    const selectedValue = JSON.parse(event.target.value);
    setNameMonitoring(selectedValue.team_name);
    setidTeam(selectedValue.team_id);
  };

  useEffect(() => {
    DataTable(idTeams);
  }, [idTeams]);

  const DataTable = (team_id) => {
    setTeamIdMonitor(team_id);
  };

  const [modalIsOpenLog, setModalIsOpen] = useState(false);

  const openModalLog = () => {
    setModalIsOpen(true);
  };

  const closeModalLog = () => {
    setModalIsOpen(false);
  };

  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  return (
    <>
      <section className="px-6 mx-auto pb-[450px] pt-14">
        <div className="container-fluid">
          <div className="flex flex-col lg:flex-row md:flex-row justify-between">
            <div className="w-full lg:w-full md:w-1/2 lg:my-6 lg:mx-2 my-2">
              <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-1 md:grid-cols-2">
                <h3 className="text-regular text-[14px] lg:text-[20px] md:text-[18px] mb-2">
                  Team: <span className="font-bold">{rank_name}</span>
                </h3>
              </div>
            </div>

            <div className="w-full mb-4 lg:my-2 lg:mx-2 flex lg:justify-end md:justify-end">
              <button
                type="button"
                onClick={openModalLog}
                className="bg-[#437998] rounded-lg px-4 py-2 font-reguler text-white w-full lg:w-[30%] md:w-[30%]"
              >
                User Log {stopwatch}
              </button>
            </div>
          </div>

          <div className="border-[1px] border-[#437998] rounded-xl p-1 mb-4 ">
            <div className="flex flex-col lg:flex-row justify-between">
              <div className="w-full lg:w-[500px] lg:my-6 lg:mx-2 my-2">
                <div className="grid grid-cols-2 gap-2 xl:grid-cols-2 xl:gap-1">
                  <select
                    onChange={(e) => setMonth(e.target.value)}
                    className="block max-w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                  >
                    {months.map((month, index) => (
                      <option key={index} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={handleMonth}
                    className="bg-redBrave rounded-lg px-4 py-2 font-bold text-white xl:mr-36"
                  >
                    Proses
                  </button>
                </div>
              </div>

              <div className="w-full lg:w-1/3 lg:my-6 lg:mx-2 my-2 border-none border-[#FFE4E4] rounded-xl bg-[#FFE4E4]">
                <h3 className="text-medium text-center text-[12px] lg:text-[16px] px-2 py-2">
                  Update terakhir :{" "}
                  {moment(ratioSTO.update_time).format(
                    "DD MMMM YYYY HH:mm:ss",
                    {
                      locale: "id",
                    }
                  )}
                </h3>
              </div>
            </div>
          </div>
        </div>

        {/* buat slain pd */}
        <div className="container-fluid mb-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div className="w-full">
              <div className="border-[1px] border-[#437998] rounded-xl p-1">
                <figure className="highcharts-figure">
                  <div id="containerSTO" className="lg:py-12 py-1"></div>
                </figure>
              </div>
            </div>
            <div className="w-full">
              <div className="border-[1px] border-[#437998] rounded-xl p-1">
                <figure className="highcharts-figure">
                  <div id="containerBDU" className="lg:py-12 py-1"></div>
                </figure>
              </div>
            </div>
          </div>
        </div>

        {show1 ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-info card-outline card-outline-tabs">
                  <div className="card-header p-0 pt-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-one-tab"
                      role="tablist"
                    >
                      <li
                        className={`nav-item col-12 ${
                          Mark !== "14" ? "col-lg-4" : "col-lg-3"
                        } col-md-4 p-0 pt-0`}
                      >
                        <a
                          className="nav-link text-center active"
                          id="custom-tabs-one-home-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-home"
                          role="tab"
                          aria-controls="custom-tabs-one-home"
                          aria-selected="true"
                        >
                          TABEL
                        </a>
                      </li>
                      <li
                        className={` nav-item col-12 ${
                          Mark !== "14" ? "col-lg-4" : "col-lg-3"
                        } col-md-4 p-0 pt-0  `}
                      >
                        <a
                          className="nav-link text-center"
                          id="custom-tabs-one-profile-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-profile"
                          role="tab"
                          aria-controls="custom-tabs-one-profile"
                          aria-selected="false"
                        >
                          GRAFIK
                        </a>
                      </li>

                      {Mark === "14" ? (
                        <li className="nav-item col-12 col-lg-3 col-md-4 p-0 pt-0">
                          <a
                            className="nav-link text-center"
                            id="custom-tabs-one-monitor-tab"
                            data-toggle="pill"
                            href="#custom-tabs-one-monitor"
                            role="tab"
                            aria-controls="custom-tabs-one-monitor"
                            aria-selected="false"
                            onClick={() => ChoiceFirst()}
                          >
                            MONITOR TARGET
                          </a>
                        </li>
                      ) : null}
                      <li
                        className={` nav-item col-12 ${
                          Mark !== "14" ? "col-lg-4" : "col-lg-3"
                        } col-md-4 p-0 pt-0 `}
                      >
                        <a
                          className="nav-link text-center"
                          id="custom-tabs-one-stok-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-stok"
                          role="tab"
                          aria-controls="custom-tabs-one-stok"
                          aria-selected="true"
                          onClick={() => ChoiceFirstSubdist()}
                        >
                          STOK
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-one-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="custom-tabs-one-home"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab"
                      >
                        {/* SM */}
                        <div ref={targetRef1} className="mt-6">
                          <section className="content">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-12">
                                  <div className={`card card-outline`}>
                                    <div className="card-header">
                                      <div className="card-tools">
                                        <button
                                          type="button"
                                          className="btn btn-tool"
                                          data-card-widget="collapse"
                                        >
                                          <i className={`fas fa-minus`} />
                                        </button>
                                      </div>
                                    </div>

                                    <div className={`card-body`}>
                                      <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                                        <h3 className="font-semibold text-lg mx-2 mb-3">
                                          {tipeRatio == "STO" ? (
                                            <>
                                              {name0} ({ratioNew.name_data}
                                              {ratioSTO.name_data} {nowDate})
                                            </>
                                          ) : (
                                            <>
                                              {name0} ({ratioNew.name_data}
                                              {ratioBDU.name_data} {nowDate})
                                            </>
                                          )}
                                        </h3>
                                        <div className="card-body">
                                          <table
                                            id="example100"
                                            className="table table-bordered table-hover table-scroll"
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "semiBold",
                                              display: "block",
                                              width: "100%",
                                              position: "relative",
                                              overflow: "auto",
                                              height: "300px",
                                            }}
                                          >
                                            <thead
                                              style={{
                                                backgroundColor: "#73605F",
                                                color: "white",
                                                textAlign: "center",
                                                top: "0",
                                                zIndex: "10",
                                                position: "sticky",
                                                fontWeight: "reguler",
                                              }}
                                            >
                                              <tr>
                                                <th
                                                  style={{
                                                    left: "0",
                                                    zIndex: "5",
                                                    position: "sticky",
                                                    verticalAlignAlign:
                                                      "middle",
                                                    backgroundColor: "#73605F",
                                                  }}
                                                >
                                                  No
                                                </th>
                                                <th
                                                  style={{
                                                    left: "60px",
                                                    zIndex: "10",
                                                    position: "sticky",
                                                    verticalAlignAlign:
                                                      "middle",
                                                    backgroundColor: "#73605F",
                                                  }}
                                                >
                                                  AREA
                                                </th>
                                                <th>SALES</th>
                                                <th>ACHIEVEMENT</th>
                                                <th>OUTLET TRANSAKSI</th>
                                              </tr>
                                            </thead>

                                            <tbody
                                              style={{ textAlign: "right" }}
                                            >
                                              {sortedData.map((list, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                        left: "0px",
                                                        zIndex: "5",
                                                        position: "sticky",
                                                        backgroundColor:
                                                          "#ffffff",
                                                      }}
                                                      width={"1%"}
                                                    >
                                                      {index + 1}.
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "left",
                                                        textDecoration:
                                                          "underline",
                                                        cursor: "pointer",
                                                        fontStyle: "italic",
                                                        color: "#3b82f6",
                                                        left: "60px",
                                                        zIndex: "5",
                                                        position: "sticky",
                                                        backgroundColor:
                                                          "#ffffff",
                                                      }}
                                                    >
                                                      <span
                                                        onClick={() =>
                                                          handleSliceClick2(
                                                            list.divisi,
                                                            list.drilldown_1,
                                                            list.name
                                                          )
                                                        }
                                                      >
                                                        {list.name}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span
                                                        className={`${
                                                          parseFloat(
                                                            list.subtotal
                                                          ) < 0
                                                            ? "text-red-500"
                                                            : ""
                                                        }`}
                                                      >
                                                        {formatToRupiah(
                                                          list.subtotal
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <span
                                                        className={`${
                                                          parseFloat(
                                                            list.target_pct
                                                          ) < 0
                                                            ? "text-red-500"
                                                            : ""
                                                        }`}
                                                      >
                                                        {Math.round(
                                                          list.target_pct * 100
                                                        ) / 100}
                                                        % (
                                                        {formatToRupiah(
                                                          list.subtotal
                                                        )}{" "}
                                                        of total {list.target})
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <span
                                                        className={`${
                                                          parseFloat(
                                                            list.outlet_transaksi
                                                          ) < 0
                                                            ? "text-red-500"
                                                            : ""
                                                        }`}
                                                      >
                                                        {formatToRupiah(
                                                          list.outlet_transaksi
                                                        )}
                                                      </span>
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                            <tfoot
                                              style={{ textAlign: "right" }}
                                              className="sticky bottom-0 z-10"
                                            >
                                              <tr className="bg-white border border-slate-800 font-bold">
                                                <td
                                                  colSpan={"2"}
                                                  style={{
                                                    textAlign: "center",
                                                    left: "0px",
                                                    zIndex: "5",
                                                    position: "sticky",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                >
                                                  TOTAL
                                                </td>
                                                <td>
                                                  {" "}
                                                  <span
                                                    className={`${
                                                      parseFloat(totalSales) < 0
                                                        ? "text-red-500"
                                                        : ""
                                                    }`}
                                                  >
                                                    {formatToRupiah(totalSales)}
                                                  </span>
                                                </td>

                                                <td
                                                  style={{
                                                    backgroundColor: "white",
                                                  }}
                                                ></td>
                                                <td
                                                  style={{
                                                    backgroundColor: "white",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {formatToRupiah(totalOutlet)}
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>
                                      </div>
                                      <div>
                                        <ChartOne
                                          tipe={Tipe}
                                          mark={Mark}
                                          marks={params.id}
                                          month={month}
                                          Id_type={id_type}
                                          name={name0}
                                          idTeam={idTeam}
                                          idTipe={"1"}
                                          color={colorPopup}
                                        />
                                      </div>
                                    </div>
                                    {/* /.card-body */}
                                  </div>
                                  {/* /.card */}
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>

                        {/* RM*/}
                        <div ref={targetRef2}>
                          {show2 ? (
                            <section className="content">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12">
                                    <div className={`card card-outline `}>
                                      <div className="card-header">
                                        <h3 className="card-title"></h3>
                                        <div className="card-tools">
                                          <button
                                            type="button"
                                            className="btn btn-tool"
                                            data-card-widget="collapse"
                                          >
                                            <i className={`fas fa-minus`} />
                                          </button>
                                        </div>
                                      </div>
                                      <div className={`card-body `}>
                                        <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                                          <h3 className="font-semibold text-lg mx-2 mb-3">
                                            {tipeRatio == "STO" ? (
                                              <>
                                                {name1} ({ratioSTO.name_data}{" "}
                                                {nowDate})
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                {name1} ({ratioBDU.name_data}{" "}
                                                {nowDate})
                                              </>
                                            )}
                                          </h3>
                                          <div className="card-body">
                                            <table
                                              id="example101"
                                              className="table table-bordered table-hover table-scroll"
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "semiBold",
                                                display: "block",
                                                width: "100%",
                                                position: "relative",
                                                overflow: "auto",
                                                height: "300px",
                                              }}
                                            >
                                              <thead
                                                style={{
                                                  textAlign: "center",
                                                  top: "0",
                                                  zIndex: "10",
                                                  position: "sticky",
                                                  fontWeight: "reguler",
                                                  backgroundColor: "#73605F",
                                                  color: "white",
                                                }}
                                              >
                                                <tr>
                                                  <th
                                                    style={{
                                                      left: "0",
                                                      zIndex: "5",
                                                      position: "sticky",
                                                      verticalAlignAlign:
                                                        "middle",
                                                      backgroundColor:
                                                        "#73605F",
                                                    }}
                                                  >
                                                    No
                                                  </th>
                                                  <th
                                                    style={{
                                                      left: "60px",
                                                      zIndex: "10",
                                                      position: "sticky",
                                                      verticalAlignAlign:
                                                        "middle",
                                                      backgroundColor:
                                                        "#73605F",
                                                    }}
                                                  >
                                                    AREA
                                                  </th>
                                                  <th>SALES</th>
                                                  <th>ACHIEVEMENT</th>
                                                  <th>OUTLET TRANSAKSI</th>
                                                </tr>
                                              </thead>

                                              <tbody
                                                style={{ textAlign: "right" }}
                                              >
                                                {sortedData2.map(
                                                  (list, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                            left: "0px",
                                                            zIndex: "5",
                                                            position: "sticky",
                                                            backgroundColor:
                                                              "#ffffff",
                                                          }}
                                                          width={"1%"}
                                                        >
                                                          {index + 1}.
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "left",
                                                            textDecoration:
                                                              "underline",
                                                            cursor: "pointer",
                                                            fontStyle: "italic",
                                                            color: "#3b82f6",
                                                            left: "40px",
                                                            zIndex: "5",
                                                            position: "sticky",
                                                            backgroundColor:
                                                              "#ffffff",
                                                          }}
                                                        >
                                                          <span
                                                            onClick={() =>
                                                              handleSliceClick3(
                                                                list.divisi,
                                                                list.drilldown_1,
                                                                list.drilldown_2,
                                                                list.name
                                                              )
                                                            }
                                                          >
                                                            {list.name}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span
                                                            className={`${
                                                              parseFloat(
                                                                list.subtotal
                                                              ) < 0
                                                                ? "text-red-500"
                                                                : ""
                                                            }`}
                                                          >
                                                            {formatToRupiah(
                                                              list.subtotal
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <span
                                                            className={`${
                                                              parseFloat(
                                                                list.target_pct
                                                              ) < 0
                                                                ? "text-red-500"
                                                                : ""
                                                            }`}
                                                          >
                                                            {Math.round(
                                                              list.target_pct *
                                                                100
                                                            ) / 100}{" "}
                                                            % (
                                                            {formatToRupiah(
                                                              list.subtotal
                                                            )}{" "}
                                                            of total{" "}
                                                            {list.target})
                                                          </span>
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <span
                                                            className={`${
                                                              parseFloat(
                                                                list.outlet_transaksi
                                                              ) < 0
                                                                ? "text-red-500"
                                                                : ""
                                                            }`}
                                                          >
                                                            {formatToRupiah(
                                                              list.outlet_transaksi
                                                            )}
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                              <tfoot
                                                style={{ textAlign: "right" }}
                                                className="sticky bottom-0 z-10"
                                              >
                                                <tr className="bg-white border border-slate-800 font-bold">
                                                  <td
                                                    colSpan={"2"}
                                                    style={{
                                                      textAlign: "center",
                                                      left: "0px",
                                                      zIndex: "5",
                                                      position: "sticky",
                                                      backgroundColor:
                                                        "#ffffff",
                                                    }}
                                                  >
                                                    TOTAL
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    <span
                                                      className={`${
                                                        parseFloat(
                                                          totalSales2
                                                        ) < 0
                                                          ? "text-red-500"
                                                          : ""
                                                      }`}
                                                    >
                                                      {formatToRupiah(
                                                        totalSales2
                                                      )}
                                                    </span>
                                                  </td>

                                                  <td
                                                    style={{
                                                      backgroundColor: "white",
                                                    }}
                                                  ></td>
                                                  <td
                                                    style={{
                                                      backgroundColor: "white",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {formatToRupiah(
                                                      totalOutlet2
                                                    )}
                                                  </td>
                                                </tr>
                                              </tfoot>
                                            </table>
                                          </div>
                                        </div>
                                        <ChartTwo
                                          tipe={Tipe}
                                          mark={Mark}
                                          marks={params.id}
                                          month={month}
                                          idTeam={idTeam}
                                          name={name1}
                                          id_type={"1"}
                                          color={colorPopup}
                                        />
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : null}
                        </div>

                        {/* AM*/}
                        <div ref={targetRef3}>
                          {show3 ? (
                            <section className="content">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12">
                                    <div className={`card card-outline `}>
                                      <div className="card-header">
                                        <h3 className="card-title"></h3>
                                        <div className="card-tools">
                                          <button
                                            type="button"
                                            className="btn btn-tool"
                                            data-card-widget="collapse"
                                          >
                                            <i className={`fas fa-minus`} />
                                          </button>
                                        </div>
                                      </div>
                                      <div className={`card-body `}>
                                        <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                                          <h3 className="font-semibold text-lg mx-2 mb-3">
                                            {name1} -{" "}
                                            {tipeRatio == "STO" ? (
                                              <>
                                                {name2} ({ratioNew.name_data}
                                                {ratioSTO.name_data} {nowDate})
                                              </>
                                            ) : (
                                              <>
                                                {name2} ({ratioNew.name_data}
                                                {ratioBDU.name_data} {nowDate})
                                              </>
                                            )}
                                          </h3>
                                          <div className="card-body">
                                            <table
                                              id="example102"
                                              className="table table-bordered table-hover table-scroll"
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "semiBold",
                                                display: "block",
                                                width: "100%",
                                                position: "relative",
                                                overflow: "auto",
                                                height: "300px",
                                              }}
                                            >
                                              <thead
                                                style={{
                                                  textAlign: "center",
                                                  top: "0",
                                                  zIndex: "10",
                                                  position: "sticky",
                                                  fontWeight: "reguler",
                                                  backgroundColor: "#73605F",
                                                  color: "white",
                                                }}
                                              >
                                                <tr>
                                                  <th
                                                    style={{
                                                      left: "0",
                                                      zIndex: "5",
                                                      position: "sticky",
                                                      verticalAlignAlign:
                                                        "middle",
                                                      backgroundColor:
                                                        "#73605F",
                                                    }}
                                                  >
                                                    No
                                                  </th>
                                                  <th
                                                    style={{
                                                      left: "60px",
                                                      zIndex: "10",
                                                      position: "sticky",
                                                      verticalAlignAlign:
                                                        "middle",
                                                      backgroundColor:
                                                        "#73605F",
                                                    }}
                                                  >
                                                    AREA
                                                  </th>
                                                  <th>SALES</th>
                                                  <th>ACHIEVEMENT</th>
                                                  <th>OUTLET TRANSAKSI</th>
                                                </tr>
                                              </thead>

                                              <tbody
                                                style={{ textAlign: "right" }}
                                              >
                                                {sortedData3.map(
                                                  (list, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                            left: "0px",
                                                            zIndex: "5",
                                                            position: "sticky",
                                                            backgroundColor:
                                                              "#ffffff",
                                                          }}
                                                          width={"1%"}
                                                        >
                                                          {index + 1}.
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "left",
                                                            textDecoration:
                                                              "underline",
                                                            cursor: "pointer",
                                                            fontStyle: "italic",
                                                            color: "#3b82f6",
                                                            left: "40px",
                                                            zIndex: "5",
                                                            position: "sticky",
                                                            backgroundColor:
                                                              "#ffffff",
                                                          }}
                                                        >
                                                          <span
                                                            onClick={() =>
                                                              handleSliceClick4(
                                                                list.divisi,
                                                                list.drilldown_1,
                                                                list.drilldown_2,
                                                                list.drilldown_3,
                                                                list.name
                                                              )
                                                            }
                                                          >
                                                            {list.name}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span
                                                            className={`${
                                                              parseFloat(
                                                                list.subtotal
                                                              ) < 0
                                                                ? "text-red-500"
                                                                : ""
                                                            }`}
                                                          >
                                                            {formatToRupiah(
                                                              list.subtotal
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <span
                                                            className={`${
                                                              parseFloat(
                                                                list.target_pct
                                                              ) < 0
                                                                ? "text-red-500"
                                                                : ""
                                                            }`}
                                                          >
                                                            {Math.round(
                                                              list.target_pct *
                                                                100
                                                            ) / 100}{" "}
                                                            % (
                                                            {formatToRupiah(
                                                              list.subtotal
                                                            )}{" "}
                                                            of total{" "}
                                                            {list.target})
                                                          </span>
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <span
                                                            className={`${
                                                              parseFloat(
                                                                list.outlet_transaksi
                                                              ) < 0
                                                                ? "text-red-500"
                                                                : ""
                                                            }`}
                                                          >
                                                            {formatToRupiah(
                                                              list.outlet_transaksi
                                                            )}
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                              <tfoot
                                                style={{ textAlign: "right" }}
                                                className="sticky bottom-0 z-10"
                                              >
                                                <tr className="bg-white border border-slate-800 font-bold">
                                                  <td
                                                    colSpan={"2"}
                                                    style={{
                                                      textAlign: "center",
                                                      left: "0px",
                                                      zIndex: "5",
                                                      position: "sticky",
                                                      backgroundColor:
                                                        "#ffffff",
                                                    }}
                                                  >
                                                    TOTAL
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    <span
                                                      className={`${
                                                        parseFloat(
                                                          totalSales3
                                                        ) < 0
                                                          ? "text-red-500"
                                                          : ""
                                                      }`}
                                                    >
                                                      {formatToRupiah(
                                                        totalSales3
                                                      )}
                                                    </span>
                                                  </td>

                                                  <td
                                                    style={{
                                                      backgroundColor: "white",
                                                    }}
                                                  ></td>
                                                  <td
                                                    style={{
                                                      backgroundColor: "white",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {formatToRupiah(
                                                      totalOutlet3
                                                    )}
                                                  </td>
                                                </tr>
                                              </tfoot>
                                            </table>
                                          </div>
                                        </div>
                                        <ChartThree
                                          tipe={Tipe}
                                          mark={Mark}
                                          marks={params.id}
                                          month={month}
                                          idTeam={idTeam1}
                                          name={name2}
                                          id_type={"2"}
                                          color={colorPopup}
                                        />
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : null}
                        </div>

                        {/* SPV */}
                        <div ref={targetRef4}>
                          {" "}
                          {show4 ? (
                            <section className="content">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12">
                                    <div className={`card card-outline `}>
                                      <div className="card-header">
                                        <h3 className="card-title"></h3>
                                        <div className="card-tools">
                                          <button
                                            type="button"
                                            className="btn btn-tool"
                                            data-card-widget="collapse"
                                          >
                                            <i className={`fas fa-minus`} />
                                          </button>
                                        </div>
                                      </div>
                                      <div className={`card-body`}>
                                        <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                                          <h3 className="font-semibold text-lg mx-2 mb-3">
                                            {name1} - {name2} -{" "}
                                            {tipeRatio == "STO" ? (
                                              <>
                                                {name3} ({ratioNew.name_data}
                                                {ratioSTO.name_data} {nowDate})
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                {name3} ({ratioNew.name_data}
                                                {ratioBDU.name_data} {nowDate})
                                              </>
                                            )}
                                          </h3>
                                          <div className="card-body">
                                            <table
                                              id="example103"
                                              className="table table-bordered table-hover table-scroll"
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "semiBold",
                                                display: "block",
                                                overflow: "scroll",
                                                position: "relative",
                                                overflow: "auto",
                                                height: "300px",
                                              }}
                                            >
                                              <thead
                                                style={{
                                                  textAlign: "center",
                                                  top: "0",
                                                  zIndex: "10",
                                                  position: "sticky",
                                                  fontWeight: "reguler",
                                                  backgroundColor: "#73605F",
                                                  color: "white",
                                                }}
                                              >
                                                <tr>
                                                  <th
                                                    style={{
                                                      left: "0",
                                                      zIndex: "5",
                                                      position: "sticky",
                                                      verticalAlignAlign:
                                                        "middle",
                                                      backgroundColor:
                                                        "#73605F",
                                                    }}
                                                  >
                                                    No
                                                  </th>
                                                  <th
                                                    style={{
                                                      left: "60px",
                                                      zIndex: "10",
                                                      position: "sticky",
                                                      verticalAlignAlign:
                                                        "middle",
                                                      backgroundColor:
                                                        "#73605F",
                                                    }}
                                                  >
                                                    AREA
                                                  </th>
                                                  <th>SALES</th>
                                                  <th>ACHIEVEMENT</th>
                                                  <th>OUTLET TRANSAKSI</th>
                                                </tr>
                                              </thead>

                                              <tbody
                                                style={{ textAlign: "right" }}
                                              >
                                                {sortedData4.map(
                                                  (list, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                            left: "0px",
                                                            zIndex: "5",
                                                            position: "sticky",
                                                            backgroundColor:
                                                              "#ffffff",
                                                          }}
                                                          width={"1%"}
                                                        >
                                                          {index + 1}.
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "left",
                                                            textDecoration:
                                                              "underline",
                                                            cursor: "pointer",
                                                            fontStyle: "italic",
                                                            color: "#3b82f6",
                                                            left: "40px",
                                                            zIndex: "5",
                                                            position: "sticky",
                                                            backgroundColor:
                                                              "#ffffff",
                                                          }}
                                                        >
                                                          <span
                                                            onClick={() =>
                                                              handleSliceClick5(
                                                                list.divisi,
                                                                list.drilldown_4,
                                                                list.name
                                                              )
                                                            }
                                                          >
                                                            {list.name}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span
                                                            className={`${
                                                              parseFloat(
                                                                list.subtotal
                                                              ) < 0
                                                                ? "text-red-500"
                                                                : ""
                                                            }`}
                                                          >
                                                            {formatToRupiah(
                                                              list.subtotal
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <span
                                                            className={`${
                                                              parseFloat(
                                                                list.target_pct
                                                              ) < 0
                                                                ? "text-red-500"
                                                                : ""
                                                            }`}
                                                          >
                                                            {Math.round(
                                                              list.target_pct *
                                                                100
                                                            ) / 100}{" "}
                                                            % (
                                                            {formatToRupiah(
                                                              list.subtotal
                                                            )}{" "}
                                                            of total{" "}
                                                            {list.target})
                                                          </span>
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <span
                                                            className={`${
                                                              parseFloat(
                                                                list.outlet_transaksi
                                                              ) < 0
                                                                ? "text-red-500"
                                                                : ""
                                                            }`}
                                                          >
                                                            {formatToRupiah(
                                                              list.outlet_transaksi
                                                            )}
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                              <tfoot
                                                style={{ textAlign: "right" }}
                                                className="sticky bottom-0 z-10"
                                              >
                                                <tr className="bg-white border border-slate-800 font-bold">
                                                  <td
                                                    colSpan={"2"}
                                                    style={{
                                                      textAlign: "center",
                                                      left: "0px",
                                                      zIndex: "5",
                                                      position: "sticky",
                                                      backgroundColor:
                                                        "#ffffff",
                                                    }}
                                                  >
                                                    TOTAL
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    <span
                                                      className={`${
                                                        parseFloat(
                                                          totalSales4
                                                        ) < 0
                                                          ? "text-red-500"
                                                          : ""
                                                      }`}
                                                    >
                                                      {formatToRupiah(
                                                        totalSales4
                                                      )}
                                                    </span>
                                                  </td>

                                                  <td
                                                    style={{
                                                      backgroundColor: "white",
                                                    }}
                                                  ></td>
                                                  <td
                                                    style={{
                                                      backgroundColor: "white",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {formatToRupiah(
                                                      totalOutlet4
                                                    )}
                                                  </td>
                                                </tr>
                                              </tfoot>
                                            </table>
                                          </div>
                                        </div>
                                        <ChartFour
                                          tipe={Tipe}
                                          mark={Mark}
                                          marks={params.id}
                                          month={month}
                                          idTeam={idTeam2}
                                          name={name3}
                                          id_type={"3"}
                                          color={colorPopup}
                                        />
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : null}
                        </div>

                        {/* MR */}
                        <div ref={targetRef5}>
                          {" "}
                          {show5 ? (
                            <section className="content">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12">
                                    <div className={`card card-outline `}>
                                      <div className="card-header">
                                        <h3 className="card-title">
                                          {tipeRatio == "STO" ? (
                                            <>
                                              {name4} ({ratioNew.name_data}
                                              {ratioSTO.name_data} {nowDate})
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              {name4} ({ratioNew.name_data}
                                              {ratioBDU.name_data} {nowDate})
                                            </>
                                          )}
                                        </h3>

                                        <div className="card-tools">
                                          <button
                                            type="button"
                                            className="btn btn-tool"
                                            data-card-widget="collapse"
                                            //onClick={() => setIsCollapsed5(false)}
                                          >
                                            <i className={`fas fa-minus`} />
                                          </button>
                                        </div>
                                      </div>
                                      <div className={`card-body `}>
                                        {" "}
                                        <ChartFive
                                          tipe={Tipe}
                                          tipers={Tipers}
                                          mark={Mark}
                                          marks={params.id}
                                          month={month}
                                          idTeam={idTeam3}
                                          names={name4}
                                          nameLast={nameLast}
                                          id_type={"4"}
                                        />
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : null}
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="custom-tabs-one-profile"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-profile-tab"
                      >
                        <GrafikHigh
                          data1={data1}
                          tipe={Tipe}
                          ratioSTO={ratioSTO}
                          ratioBDU={ratioBDU}
                          Mark={Mark}
                          nowDate={nowDate}
                          month={month}
                          color={colorPopup}
                          regionalData={regionalData}
                          dataArrays={dataArray}
                        />
                      </div>

                      {Mark === "14" ? (
                        <div
                          className="tab-pane fade"
                          id="custom-tabs-one-monitor"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-one-monitor-tab"
                        >
                          <h5 className="text-md text-gray-900 mb-8 mt-2">
                            Silakan Pilih Level Berikut ini :
                          </h5>
                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                            {listChoiceFirst.map((lists, index) => {
                              return (
                                <div
                                  key={index}
                                  className="xl:px-16 flex items-center"
                                >
                                  <h5 className="text-md text-gray-900 mr-4 font-semibold">
                                    {lists.label}
                                  </h5>
                                  <select
                                    onChange={handleChange}
                                    className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                  >
                                    {" "}
                                    <option selected disabled>
                                      Pilih
                                    </option>
                                    {lists.list.map((list, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={list.team_id}
                                        >
                                          {list.team_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              );
                            })}
                            {labelArea !== "" ? (
                              <div className="xl:px-16 flex items-center">
                                <h5 className="text-md text-gray-900 mr-4 font-semibold">
                                  {labelArea}
                                </h5>
                                <select
                                  onChange={handleChange1}
                                  className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                >
                                  <option selected disabled>
                                    Pilih
                                  </option>
                                  {dataChoice?.map((list, index) => {
                                    return (
                                      <option key={index} value={list.team_id}>
                                        {list.team_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            ) : null}

                            {labelArea1 !== "" ? (
                              <div className="xl:px-16 flex items-center">
                                <h5 className="text-md text-gray-900 mr-4 font-semibold">
                                  {labelArea1}
                                </h5>
                                <select
                                  onChange={handleChange2}
                                  className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                >
                                  <option selected disabled>
                                    Pilih
                                  </option>
                                  {dataChoice1?.map((list, index) => {
                                    return (
                                      <option key={index} value={list.team_id}>
                                        {list.team_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            ) : null}
                            {labelArea2 !== "" ? (
                              <div className="xl:px-16 flex items-center">
                                <h5 className="text-md text-gray-900 mr-4 font-semibold">
                                  {labelArea2}
                                </h5>
                                <select
                                  onChange={handleChange3}
                                  className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                >
                                  {dataChoice2.length !== 1 ? (
                                    <option selected disabled>
                                      Pilih
                                    </option>
                                  ) : null}
                                  {dataChoice2?.map((list, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={JSON.stringify({
                                          team_id: list.team_id,
                                          team_name: list.team_name,
                                        })}
                                      >
                                        {list.team_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            ) : null}
                          </div>

                          {team_idMonitor ? (
                            <>
                              <h5 className="text-md text-gray-900 mr-4 font-semibold text-center my-12">
                                MONITORING TARGET -{" "}
                                <span className="text-redBrave">
                                  {nameMonitoring}
                                </span>
                              </h5>
                              <MonitorData
                                month={month}
                                rank_name={rank_name}
                                team_id={team_idMonitor}
                                division_id={Mark}
                              />
                            </>
                          ) : null}
                        </div>
                      ) : null}

                      <div
                        className="tab-pane fade"
                        id="custom-tabs-one-stok"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-stok-tab"
                      >
                        <div className="container-fluid">
                          <div className="flex flex-col lg:flex-row md:flex-row justify-between">
                            <div className="w-full lg:w-full md:w-1/2 lg:my-6 lg:mx-2 my-2">
                              <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-1 md:grid-cols-2">
                                <h3 className="text-regular text-[14px] lg:text-[18px] md:text-[18px] mb-2">
                                  <span className="font-bold uppercase">
                                    STOK ( {colorPopup} )
                                  </span>
                                </h3>
                              </div>
                            </div>
                          </div>

                          <div className="border-[1px] border-[#437998] rounded-xl p-1 mb-4">
                            <div className="flex flex-col lg:flex-row justify-between">
                              <div className="w-full lg:w-[550px] lg:my-6 lg:mx-2 my-2">
                                <div className="grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-1 xl:grid-cols-2 xl:gap-1">
                                  {/* Flex container to align title and select in a row */}
                                  <div className="lg:flex lg:flex-row items-center">
                                    <select
                                      onChange={(e) =>
                                        setSubdist(e.target.value)
                                      }
                                      className="block max-w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                      style={{ minWidth: "250px" }}
                                    >
                                      <option selected hidden>
                                        Silahkan pilih
                                      </option>
                                      {listSubdist.map((list, index) => (
                                        <option
                                          key={index}
                                          value={list.subdist_id}
                                        >
                                          {list.subdist_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <button
                                    type="button"
                                    onClick={handleSubdist}
                                    className={`bg-redBrave rounded-lg px-4 py-2 font-bold text-white xl:mr-36 ${
                                      subdistId ? "block" : "hidden"
                                    }`}
                                  >
                                    Proses
                                  </button>
                                </div>
                              </div>
                              {waktuProses ? (
                                <div className="w-full lg:w-1/3 lg:my-6 lg:mx-2 my-2 border-none border-[#FFE4E4] rounded-xl bg-[#FFE4E4]">
                                  <h3 className="text-medium text-center text-[12px] lg:text-[16px] px-2 py-2">
                                    Tanggal Data Stok :{" "}
                                    {moment(waktuProses).format(
                                      "DD MMMM YYYY HH:mm:ss",
                                      {
                                        locale: "id",
                                      }
                                    )}
                                  </h3>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {dataSubdist.length ? (
                            <>
                              <StockSubdist
                                dataSubdist={dataSubdist}
                                subdistId={subdistId}
                                rank={Mark}
                                NilaiStok={NilaiStok}
                                AvgSales={AvgSales}
                                DoiValue={DoiValue}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
      {modalIsOpenLog ? (
        <ModalUserLog
          onCloseModal={closeModalLog}
          rank_name={rank_name}
          team_id={team_id}
        />
      ) : null}
      {loader}
    </>
  );
}
export default MainDashboard;
